import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

interface IDialogData {
  name: string;
  image: string;
  points: number;
}

@Component({
  selector: 'app-reward-details',
  templateUrl: './reward-details.component.html',
  styleUrls: ['./reward-details.component.scss'],
})
export class RewardDetailsComponent implements OnInit {
  image?: string;
  name?: string;
  points?: number;

  constructor(@Inject(MAT_DIALOG_DATA) public data: IDialogData,
              public dialogRef: MatDialogRef<RewardDetailsComponent>) {
  }

  public ngOnInit(): void {
    this.image = this.data.image;
    this.name = this.data.name;
    this.points = this.data.points;
  }

  onCloseClick(): void {
    this.dialogRef.close();
  }
}
