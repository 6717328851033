<div class="wrapper-details">
  <div class="row">
    <div class="col text-center">
      <h2>{{'Details'}}</h2>
    </div>
    <button (click)="onCloseClick()" class="close-dialog">X</button>
  </div>
  <div class="row">
    <div class="col-12">
      <div class="container wrapper-container">
        <div class="row reels-wrapper reward-details">
          <div class="col-7">
            <img [src]="image">
          </div>
          <div class="col-5">
            <div class="reward-details-txt">
              <p>{{'NAME'}}: {{name}}</p>
            </div>
            <div class="reward-details-txt">
              <p>{{'POINTS'}}: {{points * 1000}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
