<div class="container-fluid">
  <div class="wrapper"
       infiniteScroll
       [infiniteScrollDistance]="1"
       [infiniteScrollThrottle]="2"
       (scrolled)="onScroll()"
       [scrollWindow]="false"
       [infiniteScrollDisabled]="infiniteScrollStop"
  >
    <div class="table-wrapper">
      <mat-table [dataSource]="dataSource" class="mat-elevation-z8">

        <ng-container matColumnDef="id">
          <mat-header-cell *matHeaderCellDef>{{'BET_ID' | translate}}#</mat-header-cell>
          <mat-cell *matCellDef="let element">
            <span class="game-name">{{element.gameName}}</span>
            <span *ngIf="element.feature === 'Free spins'" class="feature-color">
              {{'FREE_SPINS' | translate}}
            </span>
            <span *ngIf="element.feature === 'Bonus'" class="feature-color">
              {{'BONUS' | translate}}
            </span>
            <span>{{element.id}}</span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="datetime">
          <mat-header-cell *matHeaderCellDef>{{'DATE_TIME' | translate}}</mat-header-cell>
          <mat-cell *matCellDef="let element" class="text-left column-date">
            <span>{{element.datetime | date:'dd.MM.yyyy' : '+0000'}}</span>
            <span>{{element.datetime | date:'hh:mm:ss aa' : '+0000'}}</span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="wonAmount">
          <mat-header-cell *matHeaderCellDef>{{'AMOUNT_WIN' | translate}}</mat-header-cell>
          <mat-cell
            *matCellDef="let element"
            [class.win-color]="element.wonAmount !== 0"
          >
            {{element.wonAmount}}
            <span class="iso-code">{{'PTS'}}</span>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
<!--         <div class="wrapper-container"> -->
          <mat-row
            *matRowDef="let row
          columns: displayedColumns;
          let i = index"
            [ngClass]="chosenRow === i ? 'active' : 'regular'"
            (click)="getSpinId(row.id, i)"
          ></mat-row>
<!--         </div> -->

      </mat-table>
    </div>
    <div *ngIf="dataSource?.length === 0" class="text-center mt-3">
      <h3>{{'NO_DATA' | translate}}</h3>
    </div>
  </div>
</div>
<div *ngIf="dataSource === null">
  <app-loading-spinner></app-loading-spinner>
</div>

