<div class="container-fluid">
  <div class="wrapper">
    <div class="table-wrapper">
      <mat-table [dataSource]="dataSource" class="mat-elevation-z8">

        <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef>{{'Name'}}</mat-header-cell>
          <mat-cell *matCellDef="let element">
            <span class="game-name">{{element.name}}</span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="description">
          <mat-header-cell *matHeaderCellDef>{{'Description'}}</mat-header-cell>
          <mat-cell *matCellDef="let element">
            <span>{{element.description || '-'}}</span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="wonAt">
          <mat-header-cell *matHeaderCellDef>{{'Win Date'}}</mat-header-cell>
          <mat-cell *matCellDef="let element" class="text-left column-date">
            <span *ngIf="element.wonAt">{{element.wonAt | date:'dd.MM.yyyy' : '+0000'}}</span>
            <span style="margin-left: 5px" *ngIf="element.wonAt">{{element.wonAt | date:'hh:mm:ss aa' : '+0000'}}</span>
            <span *ngIf="!element.wonAt">{{'-'}}</span>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
        <mat-row
          *matRowDef="let row
          columns: displayedColumns;
          let i = index"
          [ngClass]="chosenRow === i ? 'active' : 'regular'"
          (click)="getRewardDetail(i)"
        ></mat-row>
      </mat-table>
    </div>
  </div>
</div>

<div *ngIf="dataSource === null">
  <app-loading-spinner></app-loading-spinner>
</div>
