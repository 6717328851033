import {
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { RewardDataModel } from '../../../shared/api/models/reward-data.model';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Params } from '@angular/router';
import { HistoryRewardsService } from './history-rewards.service';
import { ApiRewardsDataRespModel } from '../../../shared/api/models/api-rewards-data-resp.model';
import { MatDialog } from '@angular/material/dialog';
import { RewardDetailsComponent } from '../reward-details/reward-details.component';

@Component({
  selector: 'app-history-rewards',
  templateUrl: './history-rewards.component.html',
  styleUrls: ['./history-rewards.component.scss'],
})
export class HistoryRewardsComponent implements OnInit, OnDestroy {
  public displayedColumns: string[] = [
    'name',
    'description',
    'wonAt',
  ];
  public dataSource: RewardDataModel[] = [];
  public chosenImage: string;
  public chosenRow: number;
  private subscription: Subscription = new Subscription();
  private playerSessionId: string = null;

  constructor(private historyRewardsService: HistoryRewardsService,
              public dialog: MatDialog,
              private activatedRoute: ActivatedRoute) {}

  public ngOnInit(): void {
    this.getSessionIdFromLink();
  }

  private getSessionIdFromLink(): void {
    this.addSubscription(
      this.activatedRoute.queryParams.subscribe((params: Params) => {
        if (params.sid) {
          this.playerSessionId = params.sid;
          this.getRewards();
        }
      }),
    );
  }

  private getRewards(): void {
    this.addSubscription(
      this.historyRewardsService.getDataHistoryRewards({ sessionId: this.playerSessionId })
        .subscribe((data: ApiRewardsDataRespModel) => {
          this.dataSource = data.rewards;
        }),
    );
  }

  public getRewardDetail(rowIndex: number): void {
    this.chosenImage =  this.dataSource[rowIndex].image;
    this.chosenRow = rowIndex;
    this.dialog.open(RewardDetailsComponent, {
      height: '400px',
      width: '600px',
      data: {
        name: this.dataSource[rowIndex].name,
        points: this.dataSource[rowIndex].points,
        image: this.chosenImage
      },
    });
  }

  private addSubscription(subscription: Subscription): void {
    this.subscription.add(subscription);
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
