<div *ngIf="spinDetailData">
  <div class="wrapper-details">
    <div class="row">
      <div class="col text-center">
        <h2>{{'BET_DETAILS' | translate}}</h2>
      </div>
      <button (click)="onCloseClick()" class="close-dialog">X</button>
    </div>
    <div class="row">
      <div class="col-7">
        <div class="container wrapper-container">
          <div class="row reels-wrapper">
            <div *ngFor="let col of reelsArr" class="reels-col">
              <ng-container  *ngIf="checkWild(col); else elseBLock">
                <div class="wild">
                  <img [src]="reelsImagesByGameId[wildExpandedImage]" alt="b_expanded">
                </div>
              </ng-container>
              <ng-template #elseBLock>
                <div class="reel" *ngFor="let row of col">
                  <img [src]="reelsImagesByGameId[row]" [alt]="row">
                </div>
              </ng-template>
            </div>
            <div class="col total-win-txt">
              <p>{{'TOTAL_WIN' | translate}}: {{spinDetailData.totalReward | currency}} <span
                class="iso-code">{{spinDetailData.currency}}</span></p>
            </div>
          </div>

        </div>
      </div>
      <!-- <div class="col-5"
           *ngIf="spinDetailData.nftImage && spinDetailData.nftName">
        <div class="container">
          <div class="nft-name mb-3">{{'NFT_WIN' | translate}}: {{spinDetailData.nftName}}</div>
          <div class="nft-img-ctn">
            <img *ngIf="spinDetailData.nftImage"
                 [src]='spinDetailData.nftImage'
                 class="nft-img"
                 alt="Nft Image">
          </div>
        </div>
      </div> -->
      <div class="col-5" *ngIf="spinDetailData.totalReward && spinDetailData.totalReward > 0">
        <div class="container wrapper-container">
          <div *ngIf="spinDetailData.totalReward && spinDetailData.totalReward > 0" class="row text-center">
            <div *ngFor="let winLine of winLinesArr" class="line col-12 col-sm-6 col-xl-4">
              <p>{{winLine.id + 1}}</p>
              <img [src]="winLinesImagesByGameId[winLine.id + 1]" [alt]="winLine.id + 1">
              <p>{{'WIN' | translate}}: {{winLine.reward | currency}} <span class="iso-code">{{spinDetailData.currency}}</span></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
